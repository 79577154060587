<template>
  <b-row no-gutters
    ><b-col cols="4" class="text-left"
      ><span class="text-uppercase medium grey-subheading">{{
        $t("events.olympics.tutorial.general_info")
      }}</span></b-col
    ><b-col
      id="days-wrapper"
      class="d-inline-flex align-items-center justify-content-between"
      ><div class="days-box">
        <span class="green text-uppercase bold larger">{{
          $t("events.olympics.tutorial.page_6.days", { 0: 1 })
        }}</span>
      </div>
      <div class="days-box">
        <span class="green text-uppercase bold larger">{{
          $t("events.olympics.tutorial.page_6.days", { 0: 2 })
        }}</span>
      </div>
      <div class="days-box">
        <span class="green text-uppercase bold larger">{{
          $t("events.olympics.tutorial.page_6.days", { 0: 3 })
        }}</span>
      </div></b-col
    ><b-col cols="12" class="text-left my-5"
      ><span class="green text-uppercase bold larger">{{
        $t("events.olympics.tutorial.page_6.heading")
      }}</span></b-col
    ><b-col
      cols="4"
      class="text-right d-flex flex-column align-items-end justify-content-start"
      ><span class="green bold large text-uppercase">{{
        $t("events.olympics.tutorial.page_6.days", { 0: 1 })
      }}</span
      ><span class="blue bold normalsize">{{
        $t("events.olympics.tutorial.page_6.current_day")
      }}</span></b-col
    ><b-col cols="7" class="text-left ml-5"
      ><p class="text-white medium large text-shadow">
        {{ $t("events.olympics.tutorial.page_6.day_1") }}
      </p></b-col
    ><b-col cols="12" class="separator"></b-col
    ><b-col
      cols="4"
      class="text-right d-flex flex-column align-items-end justify-content-start"
      ><span class="green bold large text-uppercase">{{
        $t("events.olympics.tutorial.page_6.days", { 0: 2 })
      }}</span></b-col
    ><b-col cols="7" class="text-left ml-5"
      ><p class="text-white medium large text-shadow">
        {{ $t("events.olympics.tutorial.page_6.day_2") }}
      </p></b-col
    ><b-col cols="12" class="separator"></b-col
    ><b-col
      cols="4"
      class="text-right d-flex flex-column align-items-end justify-content-start"
      ><span class="green bold large text-uppercase">{{
        $t("events.olympics.tutorial.page_6.days", { 0: 3 })
      }}</span></b-col
    ><b-col cols="7" class="text-left ml-5"
      ><p class="text-white medium large text-shadow">
        {{ $t("events.olympics.tutorial.page_6.day_3") }}
      </p></b-col
    ></b-row
  >
</template>
<script>
export default {
  name: "ModalOlympiaTutorialPage6",
};
</script>
<style scoped lang="scss">
#days-wrapper {
  margin-top: 103px;
  max-width: 644px;
  @media (max-width: 1200px) {
    max-width: 628px;
  }
  @media (max-width: 1100px) {
    max-width: 554px;
  }
}
.days-box {
  width: 176px;
  height: 127px;
  border: 6px solid #43ff00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
}
.separator {
  height: 4px;
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid #4f4f4f;
  margin-bottom: 20px;
}
</style>
